const $window     = $(window),
      $nav        = $('#nav.navbar-animated'),
      $header     = $('header:first'),
      lang        = document.documentElement.lang,
      breakpoints = {
        'xl' : 1200,
        'lg' : 992,
        'md' : 768,
        'sm' : 576,
    }
;

(function() {
    'use strict';

    window.addEventListener('load', function() {

        let forms = document.getElementsByClassName('needs-validation');
        let validation = Array.prototype.filter.call(forms, function(form) {
            form.addEventListener('submit', function(event) {
                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                form.classList.add('was-validated');
            }, false);
        });
    }, false);

    window.addEventListener('load', function() {

        let anchors = document.querySelectorAll('[data-href]');

        Array.prototype.filter.call(anchors, function(el) {

            el.addEventListener('click', function(event) {

                window.open(el.getAttribute('data-href'), el.getAttribute('target') ? el.getAttribute('target') : '_self');

            }, false);

        });

    }, false);

    window.loadImgDefer = function() {

        let imgDefer = document.getElementsByClassName('img-lazy');

        for (let i=0; i<imgDefer.length; i++) {

            if(imgDefer[i].getAttribute('data-src')) {
                imgDefer[i].setAttribute('src', imgDefer[i].getAttribute('data-src') );
            }
        }
    };

    window.navMenu = function() {

        if($nav.length && $header.width() > breakpoints.lg){

            let h = $header.outerHeight() - $nav.outerHeight();

            $window.scroll(function() {

                if ($(document).scrollTop() > h ) {

                    $nav.removeClass('navbar-light bg-white');
                    $nav.addClass(   'navbar-dark bg-dark');

                } else {

                    $nav.addClass(   'navbar-light bg-white');
                    $nav.removeClass('navbar-dark bg-dark');
                }
            });
        }
    };

    window.lazyLoad = function() {

        for (let img of document.getElementsByTagName('img')) {
            let realSrc = img.dataset.src;
            if (!realSrc) continue;

            if (isVisible(img)) {
                img.src = realSrc;
                img.dataset.src = '';
            }
        }
    };

    window.isVisible = function (elem) {

        let coords        = elem.getBoundingClientRect(),
            windowHeight  = document.documentElement.clientHeight,
            topVisible    = coords.top > 0 && coords.top < windowHeight,
            bottomVisible = coords.bottom < windowHeight && coords.bottom > 0;

        return topVisible || bottomVisible;
    };

    window.addEventListener('load', function() {
        navMenu();
        loadImgDefer();
        lazyLoad();
    });

    window.addEventListener('scroll', function() {
        lazyLoad();
    });

})();
